import React, {useState, useEffect, useCallback} from "react";
import {
	TextField,
	List,
	ListItem,
	ListItemText,
	CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {styles} from "../../utils/Styles";
import {fetchDataBigquery} from "../../../services/apiFunctions";
import {useTranslation} from "react-i18next";
import _ from "lodash";

interface ClientSelectorProps {
	clientId: string;
	setClientId: React.Dispatch<React.SetStateAction<string>>;
	setShowScreens: React.Dispatch<React.SetStateAction<boolean>>;
	dataResults: any[];
	setDataResults: React.Dispatch<React.SetStateAction<any[]>>;
}

const ClientSelector: React.FC<ClientSelectorProps> = ({
	clientId,
	setClientId,
	setShowScreens,
	dataResults,
	setDataResults,
}) => {
	const {t} = useTranslation();
	const [clientIdInput, setClientIdInput] = useState("");
	const [clientRowkey, setClientRowkey] = useState(clientId || "");
	const [searching, setSearching] = useState(false);
	const [searchMode, setSearchMode] = useState(true);
	const [searchValue, setSearchValue] = useState("");
	const [fields, setFields] = useState([]);

	// Debounced function for updating clientId
	const delayedSetSearchValue = useCallback(
		_.debounce((value) => {
			setSearchValue(value);
		}, 500),
		[]
	);

	// Handle changes in the client ID input
	const handleClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setClientIdInput(newValue);
		setShowScreens(false); // Assuming you want to hide screens during search
		delayedSetSearchValue(newValue);

	};

	// Fetch data based on the org name
	useEffect(() => {
		if (searchValue && searchValue.length > 3 && searchMode === true) {
			setSearching(true);
			fetchDataBigquery(
				"pipedrive_raw.organizations",
				"name",
				searchValue,
				true
			)
				.then((data) => {
					if (data && data.data && data.data.length === 1) {
						handleSelectComputation(data.data);
					} else if (data && data.data) {
						setDataResults(data.data);
					} else {
						setDataResults([]); // Clear results if no data
					}
					setSearching(false);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
					setDataResults([]);
				});
		}
	}, [searchValue, setDataResults, searchMode]);

	const handleSelectComputation = (computation: any) => {
		setDataResults([computation]);
		setSearchMode(false);
		setClientIdInput("");
		setFields([
			{label: "Company Name", value: computation.name},
			{label: "Owner", value: JSON.parse(computation.data).owner_name},
			{label: "Email CC", value: JSON.parse(computation.data).cc_email},
			{
				label: "Address",
				value: JSON.parse(computation.data).address_formatted_address,
			},
		] as any);
		setClientId(computation.rowkey);
		setShowScreens(true);
	};

	return (
		<div
			style={{
				padding: "20px",
				marginLeft: "-25px",
				borderRadius: "5px",
				minHeight: searchMode === true ? "40vh" : "20vh",
				backgroundColor: styles.DealSelector.backgroundColor,
				minWidth: "20vw",
				maxWidth: "20vw",
				color: styles.DealSelector.color,
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				maxHeight: "50vh",
				marginBottom: "10px",
				position: "relative",
			}}
		>
			{searchMode === true ? (
				<TextField
				label={t("orgNameSearch")}
				value={clientIdInput}
				onChange={handleClientIdChange}
				variant="standard"
				margin="normal"
				fullWidth
				InputProps={{
					style: {
						backgroundColor: "#fff",
						borderRadius: "3px",
						padding: "10px",
						fontFamily: "Karla",
						borderColor: "blue",
						position: "relative",
					},
				}}
				InputLabelProps={{
					style: {
						color: clientIdInput ? "white": "black",
						fontWeight: clientIdInput ? "bold": "normal",
						transform: clientIdInput ? "translate(0, -10px) scale(0.8)" : "translate(10px, 30px) scale(1)",
						fontFamily: "Karla",
						position: "absolute",
						zIndex: 1,
					},
				}}
			/>
			) : (
				<SearchIcon
					onClick={setSearchMode.bind(null, true)}
					style={{cursor: "pointer", marginRight: "5px"}}
				/>
			)}

			<List style={{maxHeight: "40vh", overflowY: "auto", fontFamily: "Karla"}}>
				{searching === false &&
					dataResults.length !== 1 &&
					(dataResults && dataResults.length > 1 ? (
						dataResults.map((computation) => (
							<ListItem
								key={computation.id}
								onClick={() => handleSelectComputation(computation)}
								style={{
									padding: "10px 10px",
									borderBottom: "1px solid #ccc",
									cursor: "pointer",
									fontFamily: "Karla",
									fontWeight: "bold",
									fontSize: "12px",
								}}
							>
								<ListItemText
									primary={`${computation.rowkey}: ${computation.name}`}
									style={{
										fontFamily: "Karla",
										fontWeight: "bold",
										fontSize: "12px",
										color: "white",
									}}
								/>
							</ListItem>
						))
					) : (
						<ListItem>
							<ListItemText
								primary={t("searchNoResults")}
								style={{
									fontFamily: "Karla",
									fontWeight: "bold",
									fontSize: "12px",
									color: "white",
								}}
							/>
						</ListItem>
					))}
				{searching === false && dataResults && dataResults.length === 1 && (
					<div>
						{/* <h2 style={{fontFamily: "basic", fontSize: "16px"}}>
							Company Details{" "}
						</h2> */}
						{fields.map((field: any, index) => (
							<div key={index}>
								<strong style={{fontSize: "14px"}}>{t(field.label)}</strong>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "flex-start",
									}}
								>
									<span style={{textAlign: "right"}}>{field.value}</span>
								</div>
							</div>
						))}
					</div>
				)}
				{searching === true && (
					<div>
						<CircularProgress sx={{color: "white"}} />
					</div>
				)}
			</List>
		</div>
	);
};

export default ClientSelector;
