import React, {useEffect, useState} from "react";
import {
	fetchDocData,
	fetchDocsData,
	mergeDoc,
	removeDoc,
	fetchDocDataStream,
} from "../../services/firebaseFunctions";
import FinancialOverview from "../../components/computation-financials/FinancialElement";
import {fetchMDVComputation} from "../../services/apiFunctions";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress component
import {removeFolder} from "../../components/utils/s3ImageOperations";
import {useTranslation} from "react-i18next";

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

interface InteractionDataItem {
	id: string;
	fields: any; // Update the type accordingly
	computation_present: boolean;
	pdf_present: boolean;
	costs: any[]; // Update the type accordingly
	simultaneous?: {
		usage_data: any; // Update the type accordingly
	};
	production: any; // Update the type accordingly
}

const allInteractionsPresent = (recordList: InteractionDataItem[]): boolean => {
	if (!recordList || recordList.length === 0) {
		return false;
	}

	// Check if all records in the record list have a PDF
	return recordList
		.filter((record) => record?.fields !== undefined)
		.every((record) => record.computation_present === true);
};

interface Props {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionDataItem[];
	measures: any[]; // Update the type accordingly
	selectedMeasure: string;
	pageReady: boolean;
	generalData: any[]; // Update the type accordingly
}

const getAllOptions = async () => {
	const options = await fetchDocsData("options");

	return options.map((option: any) => {
		return option; // Return each individual option object
	});
}

const EnergySavingResultSummary: React.FC<Props> = ({
	updateId,
	selectedInteraction,
	interactionData,
	selectedMeasure,
	measures,
	generalData,
	pageReady,
}) => {
	const [loading, setLoading] = useState<boolean>(false); // State to track loading state
	const [callData, setCallData] = useState<any>({});
	const [measureName, setMeasureName] = useState<string>("");
	const {t} = useTranslation();
	useEffect(() => {
		if (interactionData) {
			let status = true;
			if (interactionData.length > 0) {
				status = allInteractionsPresent(interactionData);
			}

			if (!(status === pageReady)) {
				mergeDoc("energy_saving_computations", updateId, {
					progress: {
						4: {
							done: status,
							name: "Computation results",
						},
					},
				});
			}
		}
	}, [interactionData, updateId, pageReady]);

	useEffect(() => {
		fetchDocDataStream(
			`energy_saving_computations/${updateId}/results`,
			selectedInteraction,
			setCallData
		);
	}, [updateId, selectedInteraction]);

	useEffect(() => {
		const measureName = measures.find(
			(item) => item.id === selectedMeasure
		)?.name_clean;
		console.log(callData);
		setMeasureName(measureName);
	}, [selectedMeasure, measures]);

	const formatClientInputs = (clientInputs: { [key: string]: any }, options: Option[]) => {
		if (Object.keys(clientInputs).length > 0 && options.length > 0) {
				const updatedFields = Object.entries(clientInputs).reduce(
					(acc: { [key: string]: any }, [fieldId, fieldValue]) => {
						const matchingOption = options.find(
							(option) => option.id === fieldValue
						);
						if (matchingOption) {
							acc[fieldId] = matchingOption; 
						} else {
							acc[fieldId] = fieldValue; 
						}
	
						return acc;
					},
					{}
				);
				return updatedFields;
			}
		return clientInputs;
	};

	const functionCallResult = async (
		interactionId: string,
		updateId: string,
		computationName: string,
		requestPayload: any,
		allOptions: Option[] 
	) => {

		requestPayload.client_inputs = formatClientInputs(requestPayload.client_inputs, allOptions);
		const result = await fetchMDVComputation(computationName, requestPayload);
		if (result.data) {
			mergeDoc(
				`energy_saving_computations/${updateId}/results`,
				`${interactionId}`,
				{inputs: requestPayload, outputs: {[computationName]: result.data}}
			);
		} else {
			console.log("Error in computation", JSON.stringify(requestPayload));
		}
	};

	const fetchRecords = async (measure: any, recordType: string) => {
		const recordRecords: any = []; // Array to store the fetched records

		if (measure?.[recordType] && measure?.[recordType].length > 0) {
			const recordIds = measure[recordType].map((item: any) => item.id);

			for (const recordId of recordIds) {
				try {
					// Fetch the record from Firebase using fetchDocData
					const record: any = await fetchDocData(recordType, recordId);
					if (record) {
						recordRecords.push(record); // Add the fetched record to the array
					} else {
						console.log(`Record with ID ${recordId} not found.`);
					}
				} catch (error) {
					console.error(`Error fetching record with ID ${recordId}:`, error);
				}
			}
		}

		return recordRecords;
	};
	interface Measure {
		costs: any; // Define your type for costs
		facts: any; // Define your type for facts
		name_clean: string;
	}

	async function executeForMeasures(measures: Measure[], interaction: any) {
		// Map each measure to a promise representing its execution
		const promises = measures.map(async (measure) => {
			const requestPayload: any = {
				client_inputs: {},
				costs: measure.costs,
				facts: measure.facts,
			};

			const allData = generalData[0];
			const costs = await fetchRecords(measure, "costs");
			const facts = await fetchRecords(measure, "facts");
			requestPayload.costs = costs;
			requestPayload.facts = facts;

			setLoading(true);
			for (const key in interaction.fields) {
				if (interaction.fields.hasOwnProperty(key)) {
					const fieldValue = interaction.fields[key];

					try {
						const docData = await fetchDocData(`client_inputs`, key);
						if (docData && fieldValue !== "") {
							requestPayload.client_inputs[docData.name_clean] = fieldValue;
						} else {
							console.log(`Document with key ${fieldValue} not found.`);
						}
					} catch (error) {
						console.error(
							`Error fetching document with key ${fieldValue}:`,
							error
						);
					}
				}
			}
			for (const key in allData.fields) {
				if (allData.fields.hasOwnProperty(key)) {
					const fieldValue = allData.fields[key];

					try {
						const docData = await fetchDocData(`client_inputs`, key);
						if (docData) {
							if (key === "KLrpXGk9fWGtbdgMQnwf") {
								// Some specific condition
							}
							if (key !== "FUqkOlbZMiD2Rcavh3F0" && key !== "HCYFCWj7cfFJGmrV73Od" && key !== "a12MED5Glc3OUX9ibBdo") {
								requestPayload.client_inputs[docData.name_clean] = fieldValue;
							}
						} else {
							console.log(`Document with key ${fieldValue} not found.`);
						}
					} catch (error) {
						console.error(
							`Error fetching document with key ${fieldValue}:`,
							error
						);
					}
				}
			}
			const allOptions = await getAllOptions();
			await functionCallResult(
				interaction.id,
				updateId,
				measure.name_clean,
				requestPayload,
				allOptions
			);
			return true;
		});

		// Execute all promises in parallel and wait for all to finish
		await Promise.all(promises);
		await Promise.allSettled(promises)
			.then((results) => {
				results.forEach((result, index) => {
					if (result.status === "fulfilled" && result.value) {
						console.log(`Operation ${index + 1} succeeded, pdf`, result);
					}
				});
			})
			.catch((error) => {
				console.error("An error occurred:", error);
			});
		setLoading(false);
	}

	useEffect(() => {
		const reRun = async () => {
			const interaction: any = interactionData.find(
				(item: any) => item.id === selectedInteraction
			);
			if (interaction?.computation_present === false && loading === false) {
				setLoading(true);
				await removeDoc(
					`energy_saving_computations/${updateId}/results`,
					`${selectedInteraction}`
				);
				await removeFolder(
					`/energy_saving_computations/${updateId}/${selectedInteraction}/output`
				);
				mergeDoc(
					`energy_saving_computations/${updateId}/general_inputs`,
					`all`,
					{
						report_ready: false,
					}
				);

				await executeForMeasures(measures, interaction)
					.then(() => {
						console.log("All measures processed successfully.");
					})
					.catch((error) => {
						console.error("Error processing measures:", error);
					});
				await mergeDoc(
					`energy_saving_computations/${updateId}/computation_inputs`,
					`${selectedInteraction}`,
					{computation_present: true, pdf_present: false}
				);
				setLoading(false);
			}
		};
		reRun();
	}, []);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				minWidth: "60vw",
			}}
		>
			{loading ? ( // Render loading spinner if loading is true
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Karla",
							marginTop: "150px",
						}}
					>
						{t("computationLoading")}
					</h6>
					<CircularProgress />
				</div>
			) : (
				callData?.outputs?.[measureName] &&
				Object.keys(callData?.outputs?.[measureName]).length > 0 &&
				callData?.outputs?.[measureName].costs && (
					<FinancialOverview
						displayData={callData?.outputs?.[measureName]}
						computationType="energy_saving_computations"
					/>
				)
			)}
		</div>
	);
};

export default EnergySavingResultSummary;
