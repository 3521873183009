import React, {useEffect, useState, useRef} from "react";
import {
	Box,
  CircularProgress,
  FormControlLabel,
  Switch
} from "@mui/material";
import MultiDropdownTable from "../../components/data-multi-dropdown/DataMultiDropdown";
import { styles } from "../../components/utils/Styles";
import './index.css'
import { useTranslation } from "react-i18next";
import { postEstimateCreation, fetchDataBigquery, postCreateContact, postGetContact } from "../../services/apiFunctions";

interface Product {
	id: string;
	phase: string;
	name: string;
	description: string;
	quantity?: number;
	prices: {currency: string; price: number}[];
}

interface Option {
	id: string;
	name: string;
	name_en: string;
	value?: string;
  values?: string[];
  description?: string;
}

interface DealData {
  id: string
  deal_id: string;
  deal_rowkey: string;
  deal_title: string;
  org_id: string;
  org_rowkey: string;
}

interface EstimateInput {
    selectedProducts: any
    selectedClientData: any
    selectedDealData: {selectedWorkflow?: string[]
                       selectedEstimateText: string
                       UK: boolean
                       dealTitle: string
                       dealId: string,
                       dealRowkey: string,
                       contact_id: string,
                       buildingArea: string,
                       isEnergieLabel: boolean}
}

interface CreateEstimateSectionProps {
	selectedProducts: Product[];
    selectedClientData: any;
    workflows: Option[];
    estimateTexts: Option[];
    deal: DealData;
    contact: string;
    estimateCreated: boolean;
    setEstimateCreated: (value: boolean) => void;
}

const CreateEstimateSection: React.FC<CreateEstimateSectionProps> = ({
	selectedProducts,
  selectedClientData,
	workflows,
	estimateTexts,
  deal,
  contact,
  estimateCreated,
  setEstimateCreated
}) => {
	  const [selectedWorkflow, setSelectedWorkflow] = useState<Option | null>(null)
    const [selectedEstimateText, setSelectedEstimateText] = useState<Option | null>(null)
    const [isUK, setIsUK] = useState(false);
    const { t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<string>('nl');
    const [isValidContact, setIsValidContact] = useState<boolean>(false);
    const [newContact, setNewContact] = useState<string>("");
    const [pipedriveError, setPipedriveError] = useState<boolean>(false);
    const [estimateError, setEstimateError] = useState<boolean>(false);
    const [isEnergieLabel, setIsEnergieLabel] = useState<boolean>(false);
    const [buildingArea, setBuildingArea] = useState<string>("0");
    const [estimateData, setEstimateData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const textareaRefStandardText = useRef(null);
    const textareaRefWorkflow = useRef(null);

    useEffect(() => {
        if (textareaRefStandardText.current) {
            (textareaRefStandardText.current as HTMLTextAreaElement).style.height = 'auto';
            (textareaRefStandardText.current as HTMLTextAreaElement).style.height = `${(textareaRefStandardText.current as HTMLTextAreaElement).scrollHeight}px`;
        }
        if (textareaRefWorkflow.current) {
          (textareaRefWorkflow.current as HTMLTextAreaElement).style.height = 'auto';
          (textareaRefWorkflow.current as HTMLTextAreaElement).style.height = `${(textareaRefWorkflow.current as HTMLTextAreaElement).scrollHeight}px`;
      }
    }, [selectedEstimateText, selectedWorkflow]);

    const handleButtonClick = () => {
        if (
          selectedProducts.length > 0 &&
          selectedClientData.length > 0 &&
          selectedEstimateText?.value &&
          selectedWorkflow?.name
        ) {
          const dataObject = {
            selectedProducts: selectedProducts, 
            selectedClientData: selectedClientData,
            selectedDealData: {selectedWorkflow: selectedWorkflow?.values,
                               selectedEstimateText: selectedEstimateText?.value,
                               UK: isUK,
                               dealTitle: deal.deal_title,
                               dealId: deal.deal_id,
                               dealRowkey: deal.deal_rowkey,
                               contact_id: newContact,
                               buildingArea: buildingArea,
                               isEnergieLabel: isEnergieLabel}
          };
          
          setIsLoading(true);
          handleCreateEstimate(dataObject); // Pass dataObject to a handler function
        } else {
          console.error('Please ensure all fields are selected');
        }
      };

      const handleEstimateButtonClick = () => {
        const url = estimateData.moneybird_url;
        window.open(url, '_blank');
      };

      const handleCreateEstimate = (input: EstimateInput) => {
        postEstimateCreation(input).then((data: any) => {
          if (data) {
            if (data.success){
              setEstimateData(data.data)
            }
            setEstimateCreated(data.success)
            setEstimateError(!data.success)
            setIsLoading(false);
            }
        }).catch(() => {
          setIsLoading(false);
        });
      };

      const handleContactButtonClick = () => {
        console.log("Creating contact")
        if (
          selectedClientData.length > 0 
        ) {
          const rowkey = selectedClientData[0]?.rowkey
          fetchDataBigquery(
            "app.pipedrive_org_person",
            "organization_rowkey",
            rowkey.toString()
          )
            .then((data: any) => {
              if (data && data.data[0]) {
                console.log(data.data[0])
                const dataObject = {
                  data: data.data[0],
                  UK: isUK
                };
                postCreateContact(dataObject).then((data: any) => {
                  if (data) {
                  console.log("result", data)
                  setNewContact(data.data.moneybird_id)
                  setIsValidContact(true)
                  }
                });
                
              }
              else{
                setPipedriveError(true);
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          
        } else {
          console.error('Please ensure a valid client is selected');
        }
      };


    useEffect(() => {
		if (t('language') === 'Taal'){
			setSelectedLanguage('nl')
		}
		else{
			setSelectedLanguage('en')
		}
	  }, [t]);

    useEffect(() => {
        if (selectedEstimateText && selectedEstimateText.name === 'Energielabel' && selectedEstimateText.value){
            setIsEnergieLabel(true);
        }
        else{
            setIsEnergieLabel(false);
            setBuildingArea("0"); 
        }
    }, [selectedEstimateText]);

    useEffect(() => {
      console.log("contact", contact)
      if (contact.length > 0){
        setIsValidContact(true)
        setNewContact(contact)
      }
      else{
        console.log("Getting contact")
        if (
          selectedClientData.length > 0 
        ) {
          const rowkey = selectedClientData[0]?.rowkey
          fetchDataBigquery(
            "app.pipedrive_org_person",
            "organization_rowkey",
            rowkey.toString()
          )
            .then((data: any) => {
              if (data && data.data[0]) {
                console.log(data.data[0])
                const dataObject = {
                  data: data.data[0],
                  UK: isUK
                };
                postGetContact(dataObject).then((data: any) => {
                  if (data && data.success) {
                  console.log("result", data)
                  setNewContact(data.data.moneybird_id)
                  setIsValidContact(true)
                  }
                });
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          
        } else {
          console.error('Please ensure a valid client is selected');
        }
      }
    },[contact])

    useEffect(() => {
      setEstimateCreated(false)
    },[selectedWorkflow, selectedEstimateText, isUK, selectedProducts, selectedClientData])

	return (
		<Box
			sx={{
				fontSize: "0.7rem",
				backgroundColor: "white",
				fontFamily: "Basic",
				color: "#595959",
				alignItems: "top",
				borderRadius: "5px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				padding: "10px",
			}}
		>
			<h2 style={{marginLeft: "10px"}}>{t("estimate_title")}</h2>
            <div style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              maxHeight: "60vh",
              minWidth: "30vw",
              gap: "25px",
              padding: "10px"
            }}>
            <div style={{minWidth: "30vw"}}>
            {t("workflow")}
			  	  <MultiDropdownTable
                options = {workflows as any}
                selectedOption={selectedWorkflow}
                setSelectedOption={setSelectedWorkflow}
                type='estimate'
            />
            <textarea
                ref={textareaRefWorkflow}
                value={selectedWorkflow?.description !== null ? selectedWorkflow?.description : ''}
                style={{ ...styles.input, 
                    marginRight: '8px',
                    width: '100%',  
                    fontSize: '14px', 
                    boxSizing: 'border-box',
                    overflow: 'hidden', 
                    resize: 'none'  
                }}
                readOnly
            />
            {<span className="deal_text"> {t("estimate_uk")} </span>}
            <FormControlLabel 
              control = {<Switch checked={isUK} 
                        onChange={(e) => setIsUK(e.target.checked)} 
                        color="primary"/>
                        } 
              label={isUK ? t("uk_yes") : t("uk_no")}
            />
            
            {isEnergieLabel && (
            <div className="el_padding">
            {<span className="deal_text"> {t("building_m2")} </span>}
            <input 
                type="number"  
                value={buildingArea}
                onChange={(e) => setBuildingArea(e.target.value)} 
                min={"0"}
            />
            </div>
            )}
            </div>
            <div style={{minWidth: "30vw"}}> {t("standard_text")}
            <MultiDropdownTable
                options = {estimateTexts as any}
                selectedOption={selectedEstimateText}
                setSelectedOption={setSelectedEstimateText}
                type='estimate'
            />
            <textarea
                ref={textareaRefStandardText}
                value={selectedEstimateText?.value !== null ? selectedEstimateText?.value : ''}
                style={{ ...styles.input, 
                    marginRight: '8px',
                    width: '100%',  
                    fontSize: '14px', 
                    boxSizing: 'border-box',
                    overflow: 'hidden', 
                    resize: 'none'     
                }}
                readOnly
            />
            </div>
            </div>
            <div className="split_div">

            { ! isValidContact ? (
                <button className="left_button" onClick={handleContactButtonClick}>
                    {t("make_contact")}
                </button>
            ) : ! pipedriveError ? (
              <span className="created_text"> {t("contact_found")} </span>
            ): (
              <span className="created_text"> {t("error_contact")} </span>
            )}
  
            {selectedProducts.length > 0 && selectedClientData.length > 0 && selectedEstimateText?.name !== null && selectedEstimateText?.name !== undefined && selectedWorkflow?.name !== null && selectedWorkflow?.name !== undefined && isValidContact && !estimateCreated && ! isLoading && (
            <div className="right_div">
            <button className="right_button" onClick={handleButtonClick}>
                {t("create_estimate")}
            </button>
            </div>
            )}
            {isLoading && (
              <div className="right_button">
                {<CircularProgress />}
              </div>
            )}
            {estimateCreated && (
            <div className="right_div">
            <button className="right_button" onClick={handleEstimateButtonClick}>
              {t("nav_estimate")}
            </button>
            {<span className="estimate_text"> {t("made_estimate")} </span>}
            </div>
            )}
            {estimateError && (
              <div className="right_div">
            <span className="estimate_text"> {t("error_estimate")} </span>
            </div>
            )}
            </div>
          
		</Box>
	);
};

export default CreateEstimateSection;
