import React, {useEffect, useState} from "react";
import {
	Box,
	Button,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Paper,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductSelector from "../../components/product-selector";

interface Product {
	id: string;
	phase: string;
	name: string;
	quantity?: number;
	description: string;
	prices: {currency: string; price: number}[];
}

interface ProductSectionProps {
	products: Product[];
	loading: boolean;
	selectedProduct: Product | null;
	onProductSelect: (product: Product | null) => void;
	selectedProducts: Product[];
	setSelectedProducts: (selected: Product[]) => void;
}

const ProductSection: React.FC<ProductSectionProps> = ({
	products,
	loading,
	selectedProduct,
	onProductSelect,
	selectedProducts,
	setSelectedProducts
}) => {
	const [isEditing, setIsEditing] = useState<boolean[]>([]);

	const handleButtonClick = () => {
		if (selectedProduct) {
			setSelectedProducts([...selectedProducts, selectedProduct]);
			setIsEditing([...isEditing, false]);
			onProductSelect(null);
		}
	};

	const handlePriceChange = (index: number, price: number) => {
		const updatedProducts = selectedProducts.map((product, i) =>
			i === index
				? {...product, prices: [{...product.prices[0], price}]}
				: product
		);
		setSelectedProducts(updatedProducts);
	};

	const handleQuantityChange = (index: number, quantity: number) => {
		const updatedProducts = selectedProducts.map((product, i) =>
			i === index ? {...product, quantity: quantity} : product
		);
		setSelectedProducts(updatedProducts);
	};

	const handleDelete = (index: number) => {
		const updatedProducts = selectedProducts.filter((_, i) => i !== index);
		setSelectedProducts(updatedProducts);
	};

	return (
		<Box
			sx={{
				fontSize: "0.7rem",
				backgroundColor: "white",
				fontFamily: "Basic",
				color: "#595959",
				alignItems: "top",
				borderRadius: "5px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				padding: "10px",
				marginBottom: "10px"
			}}
		>
			<h2 style={{marginLeft: "10px"}}>Select products</h2>
			<Box sx={{display: "flex", flexDirection: "row", gap: "10px"}}>
				<Box sx={{maxWidth: "500px"}}>
					<ProductSelector
						products={products}
						onSelect={onProductSelect as any}
						loading={loading}
					/>
					{selectedProduct && (
						<Box
							sx={{
								position: "relative",
								padding: "20px",
								backgroundColor: "#f9f9f9",
								fontSize: "0.6rem",
								borderRadius: "5px",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
								maxWidth: "25vw",
								fontFamily: "karla",
								marginTop: "20px",
							}}
						>
							<Button
								sx={{
									position: "absolute",
									top: "10px",
									right: "10px",
									fontSize: "0.8rem",
									backgroundColor: "#007bff",
									color: "#fff",
									"&:hover": {backgroundColor: "#0056b3"},
								}}
								onClick={handleButtonClick}
							>
								Add Product
							</Button>
							<Typography
								variant="subtitle1"
								sx={{
									marginBottom: "2px",
									fontWeight: "bold",
									fontFamily: "karla",
									fontSize: "0.9rem",
								}}
							>
								Name:
							</Typography>
							<Typography
								variant="body1"
								sx={{
									marginBottom: "12px",
									fontFamily: "karla",
									fontSize: "0.9rem",
								}}
							>
								{selectedProduct.name}
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									marginBottom: "2px",
									fontWeight: "bold",
									fontFamily: "karla",
									fontSize: "0.9rem",
								}}
							>
								Description:
							</Typography>
							<Typography
								variant="body1"
								sx={{
									marginBottom: "12px",
									fontFamily: "karla",
									maxHeight: "150px",
									overflow: "auto",
									fontSize: "0.9rem",
								}}
							>
								{selectedProduct.description || "No description available"}
							</Typography>
						</Box>
					)}
			</Box>
				<TableContainer component={Paper} sx={{flexGrow: 1, maxWidth: "35vw"}}>
					<Table aria-label="editable table">
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell align="right" style={{maxWidth: "10px"}}>
									Currency
								</TableCell>
								<TableCell align="right">Price</TableCell>
								<TableCell align="right" style={{maxWidth: "10px"}}>
									Quantity
								</TableCell>
								<TableCell align="right"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{selectedProducts.map((product, index) => (
								<TableRow key={product.id}>
									<TableCell component="th" scope="row">
										{product.name}
									</TableCell>
									<TableCell component="th" scope="row">
										{product.prices[0].currency}
									</TableCell>
									<TableCell align="right" style={{padding: "2px"}}>
										<TextField
											type="number"
											value={product.prices[0].price}
											onChange={(e) =>
												handlePriceChange(index, parseFloat(e.target.value))
											}
											inputProps={{step: "0.01"}}
										/>
									</TableCell>

									<TableCell align="right" style={{padding: "2px"}}>
										<TextField
											type="number"
											value={product?.quantity || 1}
											onChange={(e) =>
												handleQuantityChange(index, parseFloat(e.target.value))
											}
											inputProps={{step: "1"}}
										/>
									</TableCell>

									<TableCell align="right">
										<IconButton onClick={() => handleDelete(index)}>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	);
};

export default ProductSection;
