import React, {useEffect} from "react";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import TariffTable from "../../components/tariff-table";
import {useTranslation} from "react-i18next";

interface Field {
	input_categories: string;
	conditional_field?: string;
}

interface Interaction {
	id: string;
	name: string;
	fields: {
		fbzu2wmWI3MdG2BcWnIs: string;
	};
	production?: any; // Update the type according to your data structure
}
interface Template {
	[key: string]: any;
}
interface Props {
	updateId: string;
	selectedInteraction: string;
	interactionData: Interaction[];
	pageReady: boolean;
	template: Template;
}

function areAllFieldsFilled(template: Template, data: Record<string, any>) {
	const requiredCategories = ["Huurconstructie"];

	if (template) {
		const requiredFields = Object.keys(template).reduce(
			(filteredRecords: Record<string, Field>, key: string) => {
				const record = template[key];
				if (
					record.input_categories &&
					(record?.conditional_field === "" ||
						record?.conditional_field === undefined) &&
					requiredCategories.includes(record.input_categories)
				) {
					filteredRecords[key] = record;
				}
				return filteredRecords;
			},
			{}
		);

		return Object.keys(requiredFields).every(
			(field) => data[field] && data[field] !== ""
		);
	} else {
		return false;
	}
}

const SolarRentalComputationSpecs: React.FC<Props> = ({
	updateId,
	selectedInteraction,
	interactionData,
	pageReady,
	template,
}) => {
	const {t} = useTranslation();
	useEffect(() => {
		if (interactionData) {
			let status = pageReady;
			if (interactionData.length > 1) {
				const listSelection = interactionData.filter(
					(interaction: any) => interaction.selected
				);
				if (listSelection.length === 1) {
					status = true;
				}
			}

			// count if there is one interaction selected
			// console.log("status", status, pageReady)
			if (!(status === pageReady)) {
				mergeDoc("solar_rental_computations", updateId, {
					progress: {
						1: {
							done: status,
							name: "Computation specs",
						},
					},
				});
			}
		}
	}, [template, interactionData, updateId, pageReady]);

	useEffect(() => {
		console.log("template", template);
	}, [template]);

	useEffect(() => {
		console.log("interactionData", interactionData);
	}, [interactionData]);

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			{selectedInteraction !== "" && Object.keys(template).length > 0 && (
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "-10px",
							minWidth: "250px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{" "}
							{t("rentalConstruct")}
						</h3>
						<TariffTable
							updateId={updateId}
							initialRows={interactionData as any}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default SolarRentalComputationSpecs;
