import React, {useEffect, useState} from "react";
import {
	Box,
	CircularProgress
} from "@mui/material";
import './index.css'
import { useTranslation } from "react-i18next";
import TableComponent from "../../components/utils/renderTable";

interface DealData {
    id: string
	deal_id: string;
	deal_rowkey: string;
	deal_title: string;
	org_id: string;
	org_rowkey: string;
}

interface DealsSectionProps {
	deals: string[];
	searching: boolean;
    selectedDeal: DealData | undefined;
    setSelectedDeal: (item: any) => void;
}

const DealsSection: React.FC<DealsSectionProps> = ({
	deals,
	searching,
    selectedDeal,
    setSelectedDeal
}) => {
    const { t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<string>('nl');
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const columns = [
        {
			id: "org_id",
			label: "Org ID",
			accessor: (deal: DealData) => deal.org_rowkey,
		},
		{
			id: "deal_id",
			label: "Deal ID",
			accessor: (deal: DealData) => `${deal.deal_rowkey}`,
		},
		{
			id: "deal_title",
			label: "Deal Title",
			accessor: (deal: DealData) => `${deal.deal_title}`,
		},
	];

    useEffect(() => {
		if (t('language') === 'Taal'){
			setSelectedLanguage('nl')
		}
		else{
			setSelectedLanguage('en')
		}
	  }, [t]);

      useEffect(() => {
        if (selectedItem) {
            setSelectedDeal(selectedItem)
        }
	  }, [selectedItem]);

	return (
		<Box sx={{
			fontSize: "0.7rem",
			backgroundColor: "white",
			fontFamily: "Basic",
			color: "#595959",
			alignItems: "top",
			borderRadius: "5px",
			boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
			padding: "10px",
			marginBottom: "10px",
		}} >
				{searching ? (
				<CircularProgress />
			) : (
                <TableComponent
					computations={deals}
					title="Deals"
					path="estimates"
					columns={columns}
					expanded_default={true}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
				/>
			)}
				<div className="horizontal_div">
					<h2  className="hor_left">Selected Deal: </h2>
					{selectedDeal ? (
					<p className="hor_right">{selectedDeal.deal_title}</p>
					) : ( <p className="hor_right">No deal selected</p> )
					}
				</div>
            
		</Box>
	);
};

export default DealsSection;
